import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./views/Homepage";
import NoPage from "./views/NoPage";
import Tracking from "./views/Tracking";
import TrackingWithLog from "./views/TrackingWithLog";
import TrackingWithLogAdvanced from "./views/TrackingWithLogAdvanced";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/tracking/:postCode" element={<Tracking />} />
        <Route path="/basic-tracking" element={<TrackingWithLog />} />
        <Route
          path="/advanced-tracking"
          element={<TrackingWithLogAdvanced />}
        />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
