// import { Steps } from "antd";
import { Timeline } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "../App.css";
import { generateContent } from "../common/helper";
import { viewAdvancedTracking } from "../services/OrderService";

function TrackingWithLogAdvanced() {
  const [searchParams] = useSearchParams();
  const [items, setItems] = useState([]);
  const param = searchParams.get("param") as string;
  const iv = searchParams.get("iv") ?? "";

  const generateTracking = (trackings: any) => {
    return (
      <>
        {trackings &&
          trackings.map((item: any) => {
            return (
              <p className="p-2" style={{ borderBottom: "1px dashed #ccc" }}>
                <span className="font-semibold">{item?.THOI_GIAN}</span>:{" "}
                {generateContent(item)}
              </p>
            );
          })}
      </>
    );
  };

  const fetchData = async () => {
    const res = (await viewAdvancedTracking({ param, iv })) as any;
    const items = res?.data?.data;
    if (items && Array.isArray(items) && items.length > 0) {
      const fnItems = [];
      for (const item of items) {
        let obj = {
          label: item?.STATUS_NAME,
          children: generateTracking(item?.TRACKINGS),
        } as any;
        fnItems.push(obj);
      }
      setItems(fnItems as any);
    }
  };
  useEffect(() => {
    if (param && iv) {
      fetchData();
    }
  }, []);

  return (
    <div className="text-center text-lg">
      <div className="text-center mt-8 font-bold">Hành trình đơn</div>
      <div className="text-center mt-4 ml-2">
        <Timeline mode={"left"} items={items} className="pr-10" />
      </div>
    </div>
  );
}

export default TrackingWithLogAdvanced;
