import { STATUS } from "../../constants";

export const generateContent = (tracking: any) => {
  if (tracking?.TRANG_THAI == STATUS.SUCCESS) {
    return `Người nhận: ${tracking?.RECEIVER_FULLNAME}`;
  }

  if (tracking?.TRANG_THAI == STATUS.GIAO_HANG) {
    return `Nhân viên giao: ${tracking?.NHAN_VIEN_PHAT_DETAIL?.NAME} - Điện thoại: ${tracking?.NHAN_VIEN_PHAT_DETAIL?.PHONE}`;
  }

  if (tracking?.TRANG_THAI == STATUS.GIAO_NHAN) {
    let str = `Nhận tại: ${tracking?.TEN_BUUCUC_DEN}`;
    if (tracking?.QUAN_HUYEN_BUU_CUC_DI) {
      str += ` - ${tracking?.QUAN_HUYEN_BUU_CUC_DI ?? ""}`;
    }
    if (tracking?.TINH_BUU_CUC_DI) {
      str += ` - ${tracking?.TINH_BUU_CUC_DI ?? ""}`;
    }
    return str;
  }

  if (tracking?.TRANG_THAI == STATUS.VAN_CHUYEN) {
    let str: string = `Kết nối từ: ${tracking?.TEN_BUUCUC_DI}`;
    if (tracking?.QUAN_HUYEN_BUU_CUC_DI) {
      str += ` - ${tracking?.QUAN_HUYEN_BUU_CUC_DI ?? ""}`;
    }
    if (tracking?.TINH_BUU_CUC_DI) {
      str += ` - ${tracking?.TINH_BUU_CUC_DI ?? ""}`;
    }
    str += ` - Đến: ${tracking?.TEN_BUUCUC_DEN ?? ""}`;
    if (tracking?.QUAN_HUYEN_BUU_CUC_DEN) {
      str += ` - ${tracking?.QUAN_HUYEN_BUU_CUC_DEN ?? ""}`;
    }
    if (tracking?.TINH_BUU_CUC_DEN) {
      str += ` - ${tracking?.TINH_BUU_CUC_DEN ?? ""}`;
    }
    return str;
  }

  if (
    tracking?.TRANG_THAI == STATUS.NHAN_HANG ||
    tracking?.TRANG_THAI == STATUS.NHAN_HANG_TC
  ) {
    let str: string = `Nhận thành công - Nhân viên: ${tracking?.NGUOI_NHAP_MAY_DETAIL?.NAME} - Điện thoại: ${tracking?.NGUOI_NHAP_MAY_DETAIL?.PHONE}`;
    return str;
  }

  if (tracking?.TRANG_THAI == STATUS.GIAO_NHAN_HANG) {
    let str: string = `Giao nhân viên nhận`;
    if (tracking?.NHAN_VIEN_PHAT_DETAIL?.NAME) {
      str += `: ${tracking?.NHAN_VIEN_PHAT_DETAIL?.NAME}`;
    }

    if (tracking?.NHAN_VIEN_PHAT_DETAIL?.PHONE) {
      str += `- Điện thoại: ${tracking?.NHAN_VIEN_PHAT_DETAIL?.PHONE}`;
    }
    return str;
  }
  if (tracking?.TRANG_THAI == STATUS.TAO_DON) {
    let str: string = `Tạo đơn hàng`;
    if (tracking?.QUAN_HUYEN_TAO_DON) {
      str += `: ${tracking?.QUAN_HUYEN_TAO_DON}`;
    }

    if (tracking?.TINH_TAO_DON) {
      str += `- ${tracking?.TINH_TAO_DON}`;
    }
    return str;
  }

  if (
    [
      STATUS.TON_CHUYEN_HOAN,
      STATUS.DUYET_HOAN,
      STATUS.CHUYEN_HOAN,
      STATUS.HOAN_THANH_CONG,
      STATUS.KHONG_LIEN_LAC_DUOC,
    ].includes(tracking?.TRANG_THAI)
  ) {
    let str: string = ``;
    if (tracking?.NOI_DUNG) {
      str = `${tracking?.NOI_DUNG}`;
    }
    return str;
  }
};
