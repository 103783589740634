import HttpService from "./HttpService";

export const viewOrderTracking = (postCode: string) => {
  return HttpService.get(
    `service/api/v1/viettelpost/view-tracking/${postCode}`
  );
};

export const viewBasicTracking = (params: {
  postCode: string;
  source: string;
}) => {
  return HttpService.get(
    `service/api/v1/viettelpost/view-tracking?postCode=${params.postCode}&source=${params.source}`
  );
};

export const viewAdvancedTracking = (params: { param: string; iv: string }) => {
  return HttpService.get(
    `service/api/v2/viettelpost/view-tracking?param=${params.param}&iv=${params.iv}`
  );
};
